import { types } from "../actions/type";
const initialState = {
    toggleCollapsedNav:false,
    page:0,
    logo:''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_NAV:
        return {
            ...state,
            toggleCollapsedNav: !action.toggleCollapsedNav,
        }
        case types.PAGE_NO:
          return {
              ...state,
              page: action.page_no,
          }
          case types.LOGO:
            return {
                ...state,
                logo: action.logo,
            }
    default:
      return state;
  }
};