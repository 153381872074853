export const types = {
  SAMPLE_TYPE: 'SAMPLE_TYPE',
  LOGIN: 'LOGIN',
  LOGOUT:'LOGOUT',
  AUTHENTICATED: 'AUTHENTICATED',
  SHOW_AUTH_MESSAGE: 'SHOW_AUTH_MESSAGE',
  SIGNOUT: 'SIGNOUT',
  SIGNOUT_SUCCESS: 'SIGNOUT_SUCCESS',
  SHOW_LOADING: 'SHOW_LOADING',
  SHOW_AUTH_LOADING: 'SHOW_AUTH_LOADING',
  AUTH_TOKEN: 'auth_token',
  CHANGE_CLIENT_STATUS: 'CHANGE_CLIENT_STATUS',
  CHANGE_SERVICE_STATUS: 'CHANGE_SERVICE_STATUS',
  CHANGE_CUSTOMER_STATUS: 'CHANGE_CUSTOMER_STATUS',
  CHANGE_USER_STATUS: 'CHANGE_USER_STATUS',
  CUSTOMER_LIST: 'CUSTOMER_LIST',
  CUSTOMER_ACTIVE: "CUSTOMER_ACTIVE",
  ADD_CUSTOMER: 'ADD_CUSTOMER',
  EDIT_CUSTOMER: 'EDIT_CUSTOMER',
  ADD_USER: 'ADD_USER',
  EDIT_USER: 'EDIT_USER',
  STORE_CLIENT_LIST_DATA: 'STORE_CLIENT_LIST_DATA',
  CHANGE_CLIENT_STATUS_FROM_TABLE: 'CHANGE_CLIENT_STATUS_FROM_TABLE',
  ADD_CLIENT: 'ADD_CLIENT',
  EDIT_CLIENT: 'EDIT_CLIENT',
  YARD_LOCATION_LIST_DATA_FETCH: 'YARD_LOCATION_LIST_DATA_FETCH',
  YARD_LOCATION_ADD_DATA: 'YARD_LOCATION_ADD_DATA',
  YARD_LOCATION_EDIT_DATA: 'YARD_LOCATION_EDIT_DATA',
  PROFORMA_DATA:'PROFORMA_DATA',
  PROFORMA_LOAD:'PROFORMA_LOAD',
  TICKET_DETAILS:'TICKET_DETAILS',
  GET_SERVICE_LIST: 'GET_SERVICE_LIST',
  ADD_SERVICE: 'ADD_SERVICE',
  ATTENTION_LIST: 'ATTENTIO_LIST',
  ASSET_LIST: 'ASSET_LIST',
  INYARD_LIST: 'INYARD_LIST',
  CLOSEDOUT_LIST: 'CLOSEDOUT_LIST',
  TOGGLE_NAV: 'TOGGLE_NAV',
  YARD_TICKET_LIST: 'YARD_TICKET_LIST',
  YARD_TICKET_COMPLETE: 'YARD_TICKET_COMPLETE',
  REMOVE_FROM_ATTENTION_LIST: 'REMOVE_FROM_ATTENTION_LIST',
  FLUSH_YARD_LIST_DATA: 'FLUSH_YARD_LIST_DATA',
  PAGE_NO:'PAGE_NO',
  LOGO:'LOGO',
  DASHBOARD_COUNT:'DASHBOARD_COUNT',
  WASH_STATUS:'WASH_STATUS',
  ALERT_STATUS:'ALERT_STATUS',
  REVENUES_GENERATED:'REVENUES_GENERATED',
  MONTHLY_ESTIMATE:'MONTHLY_ESTIMATE',
  MONTHLY_INVOICE:'MONTHLY_INVOICE',
  MONTHLY_TICKET:'MONTHLY_TICKET',
  EQUIPMENT_AWAITING_PICKUP:'EQUIPMENT_AWAITING_PICKUP',
  TICKET_AWAITING_APPROVAL:'TICKET_AWAITING_APPROVAL',
  TOGGLE_CHANGE:'TOGGLE_CHANGE',
  BILL_REPORTS: "BILL_REPORTS",
  SET_SUCCESS:"SET_SUCCESS"
};
