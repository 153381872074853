import {types} from '../../actions/type';

export default (state = null, action) => {
  switch (action.type) {
    case types.YARD_TICKET_LIST:
      return action.payload;
    case types.YARD_TICKET_COMPLETE:
      const newState = state.filter ((val, index) => {
        return val.ticket_id != action.id;
      });
      return newState;
    case types.FLUSH_YARD_LIST_DATA:
      return null;
    default:
      return state;
  }
};
