import { types } from "../actions/type";
const initialState = {
    loading: true,
    loadingAsset:true,
    loadingInYard:true,
    loadingClosedOut:true,
    proformaLoading:true,
    proformaData:null,
    assetList: [],
    assetCount:null,
    assetPage:0,
    inYardList: [],
    inYardCount:null,
    inYardPage:0,
    closedOutList: [],
    closedOutCount:null,
    closedOutPage:0,
    ticketDetails:[]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ASSET_LIST:
        return {
            ...state,
            loadingAsset: false,
            assetCount:action.count,
            assetPage:action.page,
            assetList: action.data
        }
        case types.INYARD_LIST:
        return {
            ...state,
            loadingInYard: false,
            inYardCount:action.count,
            inYardPage:action.page,
            inYardList: action.data
        }
        case types.CLOSEDOUT_LIST:
          return {
              ...state,
              loadingClosedOut: false,
              closedOutCount:action.count,
              closedOutPage:action.page,
              closedOutList: action.data
          }
        case types.TICKET_DETAILS:
          return{
            ...state,
            loading:false,
            ticketDetails:action.data
          }
          case types.PROFORMA_DATA:
            return{
              ...state,
              proformaLoading:false,
              proformaData:action.data
            }
            case types.PROFORMA_LOAD:
              return{
                ...state,
                proformaLoading:action.loading,
              }
        case types.SHOW_LOADING: {
			return {
				...state,
				[action.loadingName?action.loadingName:'loading']: action.loading
			}
		}
    case types.LOGOUT:{
      state = initialState  
  }
    default:
      return state;
  }
};