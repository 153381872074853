import { types } from "../actions/type";
const initialState = {
    loading: false,
    client_status:undefined,
    clientId:undefined,
    newClientData:undefined,
    updateClientData:undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_CLIENT_STATUS_FROM_TABLE:
        return {
            ...state,
            loading: action.loading,
            client_status:action.client_status,
            clientId:action.clientId
            
        }
        case types.ADD_CLIENT:
          return {
              ...state,
              loading: action.loading,
              newClientData:action.newClientData,
              
          }
          case types.EDIT_CLIENT:
            return {
                ...state,
                loading: action.loading,
                updateClientData:action.updateClientData,
                
            }
        case types.SHOW_LOADING: {
			return {
				...state,
				loading: action.loading
			}
		}
    case types.LOGOUT:{
      state = initialState  
  }
    default:
      return state;
  }
};