import { types } from "../actions/type";
const initialState = {
    loading: false,
    message: '',
    showMessage: false,
    redirect: '',
    token: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AUTHENTICATED:
        return {
            ...state,
            loading: false,
            redirect: '/dashboard',
            token: action.token
        }
        case types.SHOW_AUTH_MESSAGE:
          return {
              ...state,
              message:action.message,
              showMessage:action.showMessage
          }
        case types.SHOW_AUTH_LOADING: 
			return {
				...state,
				loading: action.loading
			}
    default:
      return state;
  }
};