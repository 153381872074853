import { types } from "../actions/type";
const initialState = {
    loading: false,
    user_status:undefined,
    userId:undefined,
    newUserData:undefined,
    updateUserData:undefined
};

export default (state = initialState, action) => {
  switch (action.type) {

    case types.ADD_USER:
      return {
          ...state,
          loading: action.loading,
          newUserData:action.newUserData          
      }
      case types.EDIT_USER:
        return {
            ...state,
            loading: action.loading,
            updateUserData:action.updateUserData          
        }
    case types.CHANGE_USER_STATUS:
        return {
            ...state,
            loading: action.loading,
            user_status:action.user_status,
            userId:action.userId
            
        }
        case types.SHOW_LOADING: {
			return {
				...state,
				loading: action.loading
			}
		}
    case types.LOGOUT:{
      state = initialState  
  }
    default:
      return state;
  }
};