import {types} from '../actions/type';
const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case types.STORE_CLIENT_LIST_DATA:
      return action.payload;
    case types.CHANGE_CLIENT_STATUS_FROM_TABLE:
      const newState = state.filter ((value, index) => {
        if (value.client_id === action.clientId) {
          value.status = action.client_status;
          return value;
        } else {
          return value;
        }
      });
      return newState;
    case types.ADD_CLIENT:
      const updatedClientList = [action.newClientData, ...state];
      return updatedClientList;
      case types.LOGOUT:{
        state = initialState  
    }
    case types.EDIT_CLIENT:
      const updatedClientEditedList = state.filter ((value, index) => {
        if (value.client_id === action.updateClientData.id) {
          const {
            company_name,
            email,
            first_name,
            last_name,
            logo,
            phone,

            street_address,
          } = action.updateClientData;

          value.street_address = street_address;
          value.logo = logo;
          value.company_name = company_name;
          value.name = first_name + ' ' + last_name;
          value.phone = phone;
          value.email = email;
          value.first_name = first_name;
          value.last_name = last_name;

          return value;
        } else {
          return value;
        }
      });
      return updatedClientEditedList;
    default:
      return state;
  }
};
