import { types } from "../actions/type";

const initialState = {
    billOutList : [],
    billOutPage: 0,
    billOutCount: null,
    success: false

}


export default (state = initialState, action)=>{
    switch (action.type) {
        case types.BILL_REPORTS :
            return{
                ...state,
                loadingBillReportOut: false,
                billOutPage: action.page,
                billOutCount: action.count,
                billOutList: action.data,
                success: action.success
            }
            case types.SET_SUCCESS:
                return{
                    ...state,
                    success:action.payload
                }

            case types.SHOW_LOADING :
                console.log(action,"action");
                return{
                    ...state,
                    [action.loadingName?action.loadingName:'loading']: action.loading
                }    
        default:
            return state;
    }
}