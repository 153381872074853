import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import client from './client';
import clientData from './clientData';
import customer from './customer';
import user from './user';
import truck from './yardState/truck';
import yardLocationData from './yardLocation';
import attention from './attention';
import service from './service';
import common from './common';
import yardHandData from './yardState/YardData';
import myYard from './myYard';
import dashboard from './dashboard'
import toggleButton from "./toggleButton"
import billReports from './billReports';
export default combineReducers({
  data: app,
  auth: auth,
  client: client,
  customer: customer,
  user: user,
  clientData,
  truck: truck,
  myYard:myYard,
  yardLocationData,
  attention: attention,
  service: service,
  common: common,
  dashboard:dashboard,
  yardHandData,
  toggleButton:toggleButton,
  billReports:billReports
});
