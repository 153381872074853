import {types} from '../actions/type';
const initialState = {
  yardLocationData:[],
  loading:false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.YARD_LOCATION_LIST_DATA_FETCH:
      return {
        ...state,
        loading: action.loading,
        yardLocationData:action.payload          
    }
    case types.YARD_LOCATION_ADD_DATA:
      return {
        ...state,
        loading: action.loading,
        yardLocationData:[...state.yardLocationData,action.newYardAdd]          
    }
    case types.YARD_LOCATION_EDIT_DATA:
      const {address, name, coordinates} = action.editYardData;
      const updatedList = state.yardLocationData.filter ((value, index) => {
        if (value.id === action.id) {
          value.address = address;
          value.name = name;
          value.coordinates = coordinates;
          return value;
        } else {
          return value;
        }
      });
      return {
        ...state,
        loading: action.loading,
        yardLocationData:updatedList         
    }
    case types.SHOW_LOADING:
      return{
        ...state,loading:action.loading
      }
    default:
      return state;
  }
};
