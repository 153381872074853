import { types } from '../actions/type';
const initialState = {
  loading: true,
  list: [],
  count:null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.ATTENTION_LIST:
      return {...state,
        loading: false,
        list: action.data,
        count:action.count,
        
      };
    case types.REMOVE_FROM_ATTENTION_LIST:
      const newList = state.list.filter((val, index) => {
        return val.ticket_id != action.id;
      });
      return {
        loading: false,
        list: newList,
      };
    case types.SHOW_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case types.LOGOUT:{
      state = initialState  
  }
    default:
      return state;
  }
};
