import truck_1 from "./../../images/trucks/truck_20.svg"
import truck_2 from "./../../images/trucks/truck_20.svg"
import truck_3 from "./../../images/trucks/truck_20.svg"
import truck_4 from "./../../images/trucks/truck_20.svg"
import truck_5 from "./../../images/trucks/truck_20.svg"
import truck_6 from "./../../images/trucks/truck_20.svg"
import truck_7 from "./../../images/trucks/truck_20.svg"
import truck_8 from "./../../images/trucks/truck_20.svg"
import truck_9 from "./../../images/trucks/truck_20.svg"
import truck_10 from "./../../images/trucks/truck_20.svg"
import truck_11 from "./../../images/trucks/truck_20.svg"
import truck_12 from "./../../images/trucks/truck_20.svg"
import truck_13 from "./../../images/trucks/truck_20.svg"
import truck_14 from "./../../images/trucks/truck_20.svg"
import truck_15 from "./../../images/trucks/truck_20.svg"
import truck_16 from "./../../images/trucks/truck_20.svg"
import truck_17 from "./../../images/trucks/truck_20.svg"
import truck_18 from "./../../images/trucks/truck_20.svg"
import truck_19 from "./../../images/trucks/truck_20.svg"
import { types } from "../../actions/yardActions/truckType";
const initialState = {
  truckData: [
    { id: 1, img: truck_1, company: "united rental", truck_no: "MLC-25-0167", ticket: "1223", update: "today,04:30PM", status: "pre wash", type: "Cold Water Wash" },
    { id: 2, img: truck_2, company: "indian kongo", truck_no: "VLC-24-9134", ticket: "1432", update: "today,12:30PM", status: "pre wash", type: "Hot Water Wash" },
    { id: 3, img: truck_3, company: "american staco", truck_no: "KIN-11-1234", ticket: "4554", update: "today,07:30PM", status: "pre wash", type: "Detergent Wash" },
    { id: 4, img: truck_4, company: "kingdom final", truck_no: "ONP-47-8789", ticket: "4455", update: "today,09:30PM", status: "pre wash", type: "Diesel Wash" },
    { id: 5, img: truck_5, company: "rental maxxo", truck_no: "MAX-77-9000", ticket: "3211", update: "today,11:30PM", status: "pre wash", type: "Exterior Wash" },
    { id: 6, img: truck_6, company: "england kings", truck_no: "KOP-42-9876", ticket: "1111", update: "today,12:30PM", status: "pre wash", type: "Heel Disposal" },
    { id: 7, img: truck_7, company: "united rental", truck_no: "LOP-89-4534", ticket: "2123", update: "today,04:30PM", status: "pre wash", type: "Water Treatment" },
    { id: 8, img: truck_8, company: "american trucks", truck_no: "POU-53-1242", ticket: "6789", update: "today,07:30PM", status: "pre wash", type: "Hand Scraping" },
    { id: 9, img: truck_9, company: "indo american", truck_no: "SON-52-8790", ticket: "9009", update: "today,09:30PM", status: "pre wash", type: "Confined Space Entry" },
    { id: 10, img: truck_10, company: "kingdom final", truck_no: "KOO-99-1133", ticket: "1231", update: "today,10:30PM", status: "pre wash", type: "Internal Steam" },
    { id: 11, img: truck_11, company: "united rental", truck_no: "MLC-25-0167", ticket: "1223", update: "today,04:30PM", status: "pre wash", type: "Hydroblasting" },
    { id: 12, img: truck_12, company: "indian kongo", truck_no: "VLC-24-9134", ticket: "1432", update: "today,12:30PM", status: "pre wash", type: "Cold Water Wash" },
    { id: 13, img: truck_13, company: "american staco", truck_no: "KIN-11-1234", ticket: "4554", update: "today,07:30PM", status: "pre wash", type: "Detergent Wash" },
    { id: 14, img: truck_14, company: "kingdom final", truck_no: "ONP-47-8789", ticket: "4455", update: "today,09:30PM", status: "pre wash", type: "Diesel Wash" },
    { id: 15, img: truck_15, company: "rental maxxo", truck_no: "MAX-77-9000", ticket: "3211", update: "today,11:30PM", status: "pre wash", type: "Hand Scraping" },
    { id: 16, img: truck_16, company: "england kings", truck_no: "KOP-42-9876", ticket: "1111", update: "today,12:30PM", status: "pre wash", type: "Hot Water Wash" },
    { id: 17, img: truck_17, company: "united rental", truck_no: "LOP-89-4534", ticket: "2123", update: "today,04:30PM", status: "pre wash", type: "Heel Disposal" },
    { id: 18, img: truck_18, company: "american trucks", truck_no: "POU-53-1242", ticket: "6789", update: "today,07:30PM", status: "pre wash", type: "Cold Water Wash" },
    { id: 19, img: truck_19, company: "indo american", truck_no: "SON-52-8790", ticket: "9009", update: "today,09:30PM", status: "pre wash", type: "Hydroblasting" },
  ],
  single_truck: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case types.TRUCK_ID_CONTENT:
      let arr = state.truckData.filter((item) => item.id == parseInt(action.payload));
      arr = arr.values();
      for (let val of arr) {
        arr = val;
      }
      return {
        ...state,
        single_truck: arr,
      };
    default:
      return state;
  }
};
