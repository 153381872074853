import { types } from "../actions/type";
const initialState = {
    value: "",
};

export default (state = initialState, action) => {
    switch (action.type) {
  
      case types.TOGGLE_CHANGE:
        return {
            ...state,
            value: action.payload
        }
      default:
        return state;
    }
  };