import { types } from "../actions/type"
const initialState = {
  loading: true,
  dashboardCount: null,
  washStatus: [],
  alertStatus: [],
  revenueGenerated: [],
  monthlyEstimate: [],
  monthlyInvoice: [],
  monthlyTicket: [],
  equipmentAwaiting: [],
  ticketAwaiting: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.DASHBOARD_COUNT:
      return { ...state, loading: false, dashboardCount: action.data }
    case types.WASH_STATUS:
      return { ...state, loading: false, washStatus: action.data }
    case types.ALERT_STATUS:
      return { ...state, loading: false, alertStatus: action.data }
    case types.REVENUES_GENERATED:
      return { ...state, loading: false, revenueGenerated: action.data }
    case types.MONTHLY_ESTIMATE:
      return { ...state, loading: false, monthlyEstimate: action.data }
    case types.MONTHLY_INVOICE:
      return { ...state, loading: false, monthlyInvoice: action.data }
    case types.MONTHLY_TICKET:
      return { ...state, loading: false, monthlyTicket: action.data }
    case types.EQUIPMENT_AWAITING_PICKUP:
      return { ...state, loading: false, equipmentAwaiting: action.data }
    case types.TICKET_AWAITING_APPROVAL:
      return { ...state, loading: false, ticketAwaiting: action.data }
    case types.SHOW_LOADING: {
      return {
        ...state,
        loading: action.loading,
      }
    }
    case types.LOGOUT: {
      state = initialState
    }
    default:
      return state
  }
}
