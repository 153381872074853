import {types} from '../actions/type';

const initialState = {
    loading: true,
    list: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SERVICE_LIST:
            return {
                ...state,
                loading: false,
                list: action.data
            }
        case types.CHANGE_SERVICE_STATUS:
            let newArray = [...state.list];
            const isPresent = newArray.findIndex((i)=> i.id===action.payload.serviceID);
            newArray[isPresent].status = action.payload.status
            state.list = newArray
            return state
        case types.SHOW_LOADING: {
            return {
                ...state,
                loading: action.loading
            }
        }
        case types.LOGOUT:{
            state = initialState  
        }
        default:
          return state;
      }
} 