import { types } from "../actions/type";
const initialState = {
    loading: false,
    customer_status:undefined,
    customerId:undefined,
    newCustomerData:undefined,
    customerList:null,
    updateCustomerData:undefined,
    customerActive:null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.CUSTOMER_LIST:
      return {
          ...state,
          customerList:action.customerList          
      }
    case types.CUSTOMER_ACTIVE:
      return{
        ...state,
        customerActive:action.customerActive
      }  
    case types.ADD_CUSTOMER:
      return {
          ...state,
          loading: action.loading,
          newCustomerData:action.newCustomerData          
      }
      case types.EDIT_CUSTOMER:
        return {
            ...state,
            loading: action.loading,
            updateCustomerData:action.updateCustomerData          
        }
    case types.CHANGE_CUSTOMER_STATUS:
        return {
            ...state,
            loading: action.loading,
            customer_status:action.customer_status,
            customerId:action.customerId
            
        }
        case types.SHOW_LOADING: {
			return {
				...state,
				loading: action.loading
			}
		}
    case types.LOGOUT:{
      state = initialState  
  }
    default:
      return state;
  }
};